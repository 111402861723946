<template>
    <b-modal id="modal-xl-video" v-model="GalleryModelHideShow" hide-footer size="xl" centered title="Videos"
        @hidden="onModalHidden">
        <div class="modal_content">
            <b-row>
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                </b-col>
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-start">
                        <b-form-input v-model="gallerySearchQuery" @input="showAllVideos" placeholder="Search..." />
                    </div>
                </b-col>

            </b-row>

            <b-tabs style="height: 430px; overflow: scroll">
                <b-tab>
                    <template #title>
                        <span>{{
                            "Videos"
                        }}
                        </span>
                    </template>
                    <pagination class="m-t-10" :data="allVideos" :limit=2 @pagination-change-page="showAllVideos">
                    </pagination>
                    <div>
                        <b-row class="match-height">

                            <b-col md="2" lg="2" style="padding: 8px; position: relative" v-for="videos in allVideos.data"
                                :key="videos._id" @click="saveQuestionVideosOrBg(videos)" v-lazy-container="{
                                    selector: 'img',
                                    error:
                                        'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                    loading:
                                        'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                                }">

                                <div style="width: 200px; height: auto; object-fit: contain">
                                    <b-embed type="video" aspect="4by3" controls :poster="videos.videoThumbnail">
                                        <source :src="videos.video_uri" type="video/webm" />
                                        <source :src="videos.video_uri" type="video/x-m4v" />
                                        <source :src="videos.video_uri" type="video/mp4" />
                                        <source :src="videos.video_uri" type="video/avi" />
                                    </b-embed>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
    </b-modal>
</template>
  
<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BFormTextarea,
    BImg,
    BTh,
    BEmbed,
    BTd,
    BTbody,
    BOverlay,
    BTfoot,
    BTab,
    BTabs,
} from "bootstrap-vue";
import "sweetalert2/src/sweetalert2.scss";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BCard,
        ToastificationContent,
        BRow,
        BOverlay,
        BImg,
        BCol,
        BFormInput,
        BFormTextarea,
        BButton,
        BEmbed,
        BTable,
        BMedia,
        BAvatar,
        BFormGroup,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip,
        BTableSimple,
        BThead,
        BTr,
        BTh,
        BTd,
        BTbody,
        BTfoot,
        BTab,
        BTabs,
    },
    props: {
        props_hideShowModel: {
            type: Boolean,
            required: true,
        },
    },
    watch: {
        props_hideShowModel() {
            this.GalleryModelHideShow = this.props_hideShowModel;
        },
    },
    data() {
        return {
            gallerySearchQuery: "",
            galleryImages: {},
            AllGalleryImages: [],
            GalleryModelHideShow: false,
            allVideos: {}
        };
    },
    created() {
        this.showAllVideos();
    },
    methods: {
        onModalHidden() {
            this.$emit("modelClose", false);
        },
        saveQuestionBackground(params) {
            this.$emit("saveCategoryVideo", params);
            this.GalleryModelHideShow = false;
        },
        saveQuestionVideosOrBg(params) {
            this.$emit("saveCategoryVideo", params);
            this.GalleryModelHideShow = false;
        },

        showAllVideos(page = 1, query) {
            query = this.gallerySearchQuery;

            axios
                .get(process.env.VUE_APP_API_URL + `/zape-cms/videos/pagination?page=${page}&query=${query}`, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("_t"),
                    },
                })
                .then((get_response) => {
                    this.allVideos = get_response.data;
                });
        },
    },
};
</script>
