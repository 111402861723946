<template>
  <b-sidebar id="add-edit-category-sidebar" :visible="isAddEditCategorySidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-edit-category-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">Edit Topics</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Full Name -->
          <validation-provider #default="validationContext" name="Topic Name" rules="required">
            <b-form-group label="Topic Name" label-for="Topic Name">
              <b-form-input id="Edit-Category" v-model="CategoryData.category_name" autofocus
                :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="Sort Category" rules="required">
            <b-form-group label="Sort Category" label-for="Sort Category">
              <b-form-input id="Sort-Category" v-model="CategoryData.order" autofocus
                :state="getValidationState(validationContext)" trim type="number" placeholder="Enter Numeric Value" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <br />

          <img style="width: 200px; height: 150px" :src="CategoryData.category_img" />
          <br />
          <br />
          <b-button variant="primary" @click="showgalleryimage">
            <!-- <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer"
            /> -->
            <span class="d-none d-sm-inline">Images gallery</span>
            <!-- <feather-icon icon="EditIcon" class="d-inline d-sm-none" /> -->
          </b-button>
          <b-button style="margin-left: 10px" variant="primary" @click="$refs.refInputEl.click()">
            <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer" />
            <span class="d-none d-sm-inline">Upload</span>
            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
          </b-button>

          <zape-gallery-model :props_hideShowModel="showImage" @modelClose="modelClose"
            @saveCategoryImage="saveCategoryImage" />
            <br />
          <br />
          <img style="width: 150px; height: 120px" v-if="CategoryData.video == null"
            src="https://zape02.s3.ap-southeast-1.amazonaws.com/0.76871584038628660.98656671003321120.19322443436631032.png" />
          <div style="width: 150px; height:   120px; object-fit: contain"
            v-if="CategoryData.video != null && CategoryData.video.video_uri">
            <b-embed type="video" aspect="4by3" controls :poster="CategoryData.video.videoThumbnail">
              <source :src="CategoryData.video.video_uri" type="video/webm" />
              <source :src="CategoryData.video.video_uri" type="video/x-m4v" />
              <source :src="CategoryData.video.video_uri" type="video/mp4" />
              <source :src="CategoryData.video.video_uri" type="video/avi" />
            </b-embed>
          </div>

          <b-button variant="primary" @click="showVideosModel">
            <span class="d-none d-sm-inline">Select</span>
          </b-button>
          <zape-video-model :props_hideShowModel="statusVideosModel" @modelClose="modelVideoClose"
            @saveCategoryVideo="saveCategoryVideo" />

          <div class="d-flex bg-light text-light align-items px-3 py-2"
            style="position: absolute; bottom: 0%; left: 0%; width: 100%">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="submit" @click="hide"
              :disabled="submitbutton">
              update
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" style="margin-left: 10px"
              variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
 

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BCol,
  BEmbed,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "../../../../@fake-db/data/other/countries";
import store from "@/store";
import axios from "axios";
import S3 from "aws-s3";
import ZapeGalleryModel from "../../../components/Model/ZapeGalleryModel.vue"
import ZapeVideoModel from "../../../components/Model/ZapeVideoModel.vue"
export default {
  components: {
    ZapeVideoModel,
    ZapeGalleryModel,
    BSidebar,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BEmbed,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddEditCategorySidebarActive",
    event: "update:is-add-edit-category-sidebar-active",
  },
  props: {
    isAddEditCategorySidebarActive: {
      type: Boolean,
      required: true,
    },
    Editclassdata: {
      type: String,
      required: true,
    },
    Editsubjectid: {
      type: String,
      required: true,
    },
    Editcategoryid: {
      type: String,
      required: true,
    },
    Editcategoryname: {
      type: String,
      required: true,
    },
    EditCategoryImage: {
      type: String,
      required: true,
    },
    editOrder: {
      type: String,
      required: true,
    },
    EditCategoryVideo: {
      type: Object,
      required: true,
    }
  },
  watch: {
    Editcategoryname() {
      this.CategoryData.category_name = this.Editcategoryname;
    },
    editOrder() {
      this.CategoryData.order = this.editOrder;
    },
    EditCategoryImage() {
      this.CategoryData.category_img = this.EditCategoryImage;
    },
    EditCategoryVideo() {
      this.CategoryData.video = this.EditCategoryVideo;
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      searchQuery: "",
      showImage: false,
      removebutton: true,
      CategoryData: {
        category_name: "",
        category_img: "",
        order: 1,
        video: {}
      },
      table_data: {},
      orginal_name: "",
      edit_category_data: "",
      statusVideosModel: false
    };
  },
  created() {
    // this.gettabledata();
  },
  computed: {
    submitbutton() {
      return !(
        this.CategoryData.category_name.length > 0 &&
        this.CategoryData.category_img.length > 0
      );
    },
  },
  methods: {
    saveCategoryVideo(categoryVideo) {
      this.CategoryData.video = categoryVideo;

    },
    showVideosModel() {
      this.statusVideosModel = true;
    },
    modelVideoClose() {
      this.statusVideosModel = false;
    },
    saveCategoryImage(categoryImage) {
      this.CategoryData.category_img = categoryImage;
    },
    modelClose(modelState) {
      this.showImage = modelState;
    },
    getImages() {
      if (this.searchQuery.trim().length > 0) {
        return this.table_data.filter((item) => {
          if (item.image_name) {
            return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.image_name.toLowerCase().includes(v));
          }
        });
      } else {
        return this.table_data;
      }
    },
    gettabledata: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/zape-cms/gallery", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          // console.log(response.data);
          this.table_data = get_response.data;
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.CategoryData = {
        category_name: this.Editcategoryname,
        category_img: this.EditCategoryImage,
        order: this.editOrder,
      };
      this.searchQuery = "";
      this.showImage = false;
      this.removebutton = true;
      this.validated = false;
    },
    onSubmit() {
      this.CategoryData.img_name = this.orginal_name["name"];
      this.CategoryData.id = this.Editclassdata;
      this.CategoryData.subject_id = this.Editsubjectid;
      this.CategoryData.category_id = this.Editcategoryid;
      this.edit_category_data = this.CategoryData;
      this.$emit("clickEditCategorydata", this.edit_category_data);
      this.CategoryData.category_name = null;
    },
    showgalleryimage() {
      this.showImage = true;
      this.removebutton = false;
    },
    hideimagegallery() {
      this.showImage = false;
      this.removebutton = true;
    },
    galleryimg(data) {
      this.CategoryData.category_img = data;
      this.CategoryData.value = "1";
    },

    inputImageRenderer(input) {
      this.orginal_name = input.target.files[0];
      if (input.target.files && input.target.files[0]) {
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFN2FQFVLZ",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        S3Client.uploadFile(input.target.files[0], image)
          .then((data) => (this.CategoryData.category_img = data.location))
          .catch((err) => console.error(err));
        this.CategoryData.value = 0;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
